// src/pages/PromotionPlans.js
import React from 'react';
import '../App.css'; // Import your CSS file for styling
import PlanHeader from '../components/PlanHeader';
import PlanRow from '../components/PlanRow'; // Import the new PlanRow component
import { PLAN_PRICING } from '../utils/constants';
import { WHATSAPP_NUMBER } from '../utils/constants';
import Footer from '../components/Footer';
const PromotionPlans = () => {
  return (
    <div>
      <PlanHeader />
      <div className="promotion-plans">
        <h1 style={{ marginBottom: "20px" }}>Plans Comparison</h1>
        <table>
          <thead>
            <tr>
              <th>COMPARE ALL PLANS</th>
              <th>Explorer Plan</th>
              <th>Basic Plan</th>
              <th>Pro Plan</th>
              <th>Ultimate Plan</th>
            </tr>
          </thead>
          <tbody>
            <PlanRow
              feature="Pricing"
              explorer={PLAN_PRICING.EXPLORER}
              basic={PLAN_PRICING.BASIC}
              pro={PLAN_PRICING.PRO}
              ultimate={PLAN_PRICING.ULTIMATE}
            />
            <PlanRow
              feature="Payment Type"
              explorer="-"
              basic="Onetime"
              pro="Yearly"
              ultimate="Yearly"
            />
            <PlanRow
              feature="Role-Based Access Control"
              explorer="❌"
              basic="❌"
              pro="❌"
              ultimate="✅"
            />
            <PlanRow
              feature="User Profiles"
              explorer="Single-User"
              basic="Single-User"
              pro="Single-User"
              ultimate="Multi-User"
            />
            <PlanRow
              feature="Stock Levels Monitoring"
              explorer="❌"
              basic="✅"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="Expiry Date Tracking"
              explorer="✅"
              basic="✅"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="Batch Management"
              explorer="❌"
              basic="❌"
              pro="❌"
              ultimate="✅"
            />
            <PlanRow
              feature="Discount Management"
              explorer="❌"
              basic="✅"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="Tax Calculations"
              explorer="✅"
              basic="✅"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="Quick Bill Generation"
              explorer="500 bills/month"
              basic="✅"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="Invoice Printing"
              explorer="500 Invoice/month"
              basic="✅"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="Invoice Emailing"
              explorer="❌"
              basic="✅"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="Multi-Currency Support"
              explorer="❌"
              basic="✅"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="Payment History"
              explorer="✅"
              basic="✅"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="Partial Payments"
              explorer="✅"
              basic="✅"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="Customer Profiles"
              explorer="❌"
              basic="✅"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="Sales Reports"
              explorer="❌"
              basic="✅"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="Inventory Reports"
              explorer="❌"
              basic="✅"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="Customer Insights"
              explorer="❌"
              basic="❌"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="Financial Reports"
              explorer="❌"
              basic="✅"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="Medication History"
              explorer="❌"
              basic="✅"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="Dosage and Instructions"
              explorer="❌"
              basic="✅"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="Low Stock Alerts"
              explorer="❌"
              basic="✅"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="Payment Reminders"
              explorer="❌"
              basic="✅"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="Expiry Alerts"
              explorer="❌"
              basic="✅"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="Audit Logs"
              explorer="❌"
              basic="❌"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="Backup and Recovery"
              explorer="❌"
              basic="❌"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="POS System Integration"
              explorer="❌"
              basic="❌"
              pro="❌"
              ultimate="✅"
            />
            <PlanRow
              feature="Barcode Scanning"
              explorer="❌"
              basic="❌"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="Intuitive Dashboard"
              explorer="❌"
              basic="✅"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="Search Functionality"
              explorer="✅"
              basic="✅"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="Help Desk Integration"
              explorer="❌"
              basic="❌"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="FAQs and Guides"
              explorer="✅"
              basic="✅"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="Custom Invoice Templates"
              explorer="❌"
              basic="❌"
              pro="❌"
              ultimate="✅"
            />
            <PlanRow
              feature="Local Storage"
              explorer="✅"
              basic="✅"
              pro="✅"
              ultimate="✅"
            />
            <PlanRow
              feature="Online Storage"
              explorer="❌"
              basic="❌"
              pro="❌"
              ultimate="✅"
            />
            <tr>
              <td></td>
              <td>
                <button className="buy-now">Download Now</button>
              </td>
              <td>
                <a href={`https://api.whatsapp.com/send/?phone=${WHATSAPP_NUMBER}&text=basic`} className="btn-buy-now" target="_blank" rel="noopener noreferrer">Whatsapp Us</a>
              </td>
              <td>
              <a href={`https://api.whatsapp.com/send/?phone=${WHATSAPP_NUMBER}&text=pro`} className="btn-buy-now" target="_blank" rel="noopener noreferrer">Whatsapp Us</a>
              </td>
              <td>
              <a href={`https://api.whatsapp.com/send/?phone=${WHATSAPP_NUMBER}&text=ultimate`} className="btn-buy-now" target="_blank" rel="noopener noreferrer">Whatsapp Us</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Footer/>
    </div>
  );
};

export default PromotionPlans;
