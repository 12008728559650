import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';

const PlanHeader = () => {
  return (
    <header id="header" className="header d-flex align-items-center sticky-top">
      <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
        <a href="/" className="logo d-flex align-items-center">
          <h1 className="sitename"><span>Pharma</span>QuickBill</h1>
        </a>
        <nav id="navmenu" className="navmenu">
          <ul>
          <a href="#" className="btn-livedemo">Live Demo</a>
            
          </ul>
          
        </nav>

      </div>
    </header>
  );
};

export default PlanHeader;