export const PLAN_PRICING = {
    EXPLORER: "₹0",
    BASIC: "₹5000",
    PRO: "₹2999 /year",
    ULTIMATE: "₹4999 /Year",
    EXPLORER_VALUE: "0",
    BASIC_VALUE: "5000",
    PRO_VALUE: "2999",
    ULTIMATE_VALUE: "4999"
  };

export const WHATSAPP_NUMBER = "7907009385"