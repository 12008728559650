import React, { useState } from 'react';

const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    pharmacy: '',
    message: ''
  });
  
  const [statusMessage, setStatusMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dataToSubmit = {
      name: formData.name,
      email: formData.email,
      phone: formData.number,
      store_name: formData.pharmacy,
      message: formData.message,
    };

    try {
      const response = await fetch('https://website-ddpswwu7.an.gateway.dev/contact_us', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-Key': 'AIzaSyCAVPBYQ-IU-EMmlvziBy_jDGU_l3R5N28'
        },
        body: JSON.stringify(dataToSubmit),
      });

      if (response.ok) {
        setStatusMessage('Your message has been sent. Thank you!');
      } else {
        setStatusMessage('Error: Something went wrong.');
      }
    } catch (error) {
      setStatusMessage('Error: Unable to send message.');
    }
  };

  return (
    <section id="contact" className="contact section">
      <div className="container section-title" data-aos="fade-up">
        <h2>Contact</h2>
        <div><span>Need Help?</span> <span className="description-title">Contact Us</span></div>
      </div>

      <div className="container" data-aos="fade" data-aos-delay="100">
        <div className="row gy-4">
          <div className="col-lg-4">
            <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="200">
              <i className="bi bi-geo-alt flex-shrink-0"></i>
              <div>
                <h3>Address</h3>
                <p>HSR Layout, Bengaluru, Karnataka, India</p>
              </div>
            </div>

            <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="300">
              <i className="bi bi-telephone flex-shrink-0"></i>
              <div>
                <h3>Call Us</h3>
                <p>+91 7907009385</p>
              </div>
            </div>

            <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="400">
              <i className="bi bi-envelope flex-shrink-0"></i>
              <div>
                <h3>Email Us</h3>
                <p>pharmaquickbill@gmail.com</p>
              </div>
            </div>
          </div>

          <div className="col-lg-8">
            <form onSubmit={handleSubmit} className="php-email-form" data-aos="fade-up" data-aos-delay="200">
              <div className="row gy-4">
                <div className="col-md-6">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="col-md-6">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder="Email ID"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    name="number"
                    placeholder="Mobile Number"
                    value={formData.number}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    name="pharmacy"
                    placeholder="Pharmacy Name"
                    value={formData.pharmacy}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="col-md-12">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="6"
                    placeholder="Message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="col-md-12 text-center">
                  {statusMessage && <div className="status-message">{statusMessage}</div>}
                  <button type="submit">Send Message</button>
                </div>
              </div>
            </form> 
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
