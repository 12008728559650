import React from 'react';

const Features = () => {
  return (
    <section id="features" className="features section light-background">
      <div className="container section-title">
        <h2>Features</h2>
        <div><span>Check Our</span> <span className="description-title">Features</span></div>
      </div>
      <div className="container">
        <div className="row gy-5 justify-content-between">
          <div className="col-xl-5">
            <img src="/assets/assets/img/template/eStartup/features.svg" className="img-fluid" alt="Features" />
          </div>
          <div className="col-xl-6 d-flex">
            <div className="row align-self-center gy-4">
              <div className="col-md-6">
                <div className="feature-box d-flex align-items-center">
                  <i className="bi bi-check"></i>
                  <h3>Quick Billing</h3>
                </div>
              </div>
              <div className="col-md-6">
                <div className="feature-box d-flex align-items-center">
                  <i className="bi bi-check"></i>
                  <h3>Inventory Integration</h3>
                </div>
              </div>
              <div className="col-md-6">
                <div className="feature-box d-flex align-items-center">
                  <i className="bi bi-check"></i>
                  <h3>Custom Reports</h3>
                </div>
              </div>
              <div className="col-md-6">
                <div className="feature-box d-flex align-items-center">
                  <i className="bi bi-check"></i>
                  <h3>User Access Control</h3>
                </div>
              </div>
              <div className="col-md-6">
                <div className="feature-box d-flex align-items-center">
                  <i className="bi bi-check"></i>
                  <h3>Automated Reminders</h3>
                </div>
              </div>
              <div className="col-md-6">
                <div className="feature-box d-flex align-items-center">
                  <i className="bi bi-check"></i>
                  <h3>Data Backup</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;