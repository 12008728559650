import React from 'react';

const Services = () => {
  return (
    <section id="services" className="services section">
      <div className="container section-title">
        <h2>Services</h2>
        <div><span>Check Our</span> <span className="description-title">Services</span></div>
      </div>
      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-4 col-md-6">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="bi bi-activity"></i>
              </div>
              <a href="service-details.html" className="stretched-link">
                <h3>Streamlined Transactions</h3>
              </a>
              <p>Ensure all customer transactions are processed quickly and accurately, minimizing errors and improving efficiency.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="bi bi-broadcast"></i>
              </div>
              <a href="service-details.html" className="stretched-link">
                <h3>Real-Time Reporting</h3>
              </a>
              <p>Access detailed reports on transactions, helping you keep track of sales and monitor your pharmacy’s financial health in real-time.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="bi bi-easel"></i>
              </div>
              <a href="service-details.html" className="stretched-link">
                <h3>Customizable Invoices</h3>
              </a>
              <p>Generate and customize invoices to meet the specific needs of your pharmacy, providing clear and professional documentation for your customers.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="bi bi-bounding-box-circles"></i>
              </div>
              <a href="service-details.html" className="stretched-link">
                <h3>Secure Data Management</h3>
              </a>
              <p>Safeguard your sensitive customer and financial data with industry-standard security features built into the app.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="bi bi-calendar4-week"></i>
              </div>
              <a href="service-details.html" className="stretched-link">
                <h3>User-Friendly Interface</h3>
              </a>
              <p>Navigate through the app with ease, thanks to an intuitive design that simplifies the billing process for all users.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="bi bi-chat-square-text"></i>
              </div>
              <a href="service-details.html" className="stretched-link">
                <h3>Comprehensive Support</h3>
              </a>
              <p>Receive dedicated support to help you resolve any issues quickly, ensuring your billing operations run smoothly without interruptions.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;