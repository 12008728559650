import React from 'react';
import { PLAN_PRICING } from '../utils/constants';
const Pricing = () => {
  return (
    <section id="pricing" className="pricing section">
      {/* Section Title */}
      <div className="container section-title" data-aos="fade-up">
        <h2>Pricing</h2>
        <div>
          <span>Check Our</span> <span className="description-title">Pricing</span>
        </div>
      </div>
      {/* End Section Title */}

      <div className="container">
        <div className="row gy-4">
          {/* Free Plan */}
          <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="100">
            <div className="pricing-tem">
              <h3 style={{ color: '#20c997' }}>Explorer Plan</h3>
              <div className="price"><sup>₹</sup>{PLAN_PRICING.EXPLORER_VALUE}<span> / mo</span></div>
              <div className="icon">
                <i className="bi bi-box" style={{ color: '#20c997' }}></i>
              </div>
              <ul>
                <li>Free Plan</li>
                <li>500 Bills/Month</li>
                <li>Single User Login</li>
                <li>Sales Reports</li>
                <li>Expiry Alerts</li>
                <li className="na">Barcode Scanning</li>
                <li className="na">Customer Loyalty Programs</li>
                <li className="na">New Feature Updates</li>
              </ul>
              <a href="/plans" target="_blank" rel="noopener noreferrer" className="btn-buy">View Details</a>
            </div>
          </div>
          {/* End Pricing Item */}

          {/* Starter Plan */}
          <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="200">
            <div className="pricing-tem">
              <span className="featured">Featured</span>
              <h3 style={{ color: '#0dcaf0' }}>Basic Plan</h3>
              <div className="price"><sup>₹</sup>{PLAN_PRICING.BASIC_VALUE}</div>
              <div className="icon">
                <i className="bi bi-send" style={{ color: '#0dcaf0' }}></i>
              </div>
              <ul>
                <li>Onetime Payment</li>
                <li>Billing and Invoicing</li>
                <li>Single User Login</li>
                <li>Inventory Management</li>
                <li>Customer Management</li>
                <li>Notifications and Alerts</li>
                <li className="na">Prescription Management</li>
                <li className="na">New Feature Updates</li>
              </ul>
              <a href="/plans" target="_blank" rel="noopener noreferrer" className="btn-buy">View Details</a>
            </div>
          </div>
          {/* End Pricing Item */}

          {/* Business Plan */}
          <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="300">
            <div className="pricing-tem">
              <h3 style={{ color: '#fd7e14' }}>Pro Plan</h3>
              <div className="price"><sup>₹</sup>{PLAN_PRICING.PRO_VALUE}<span> / year</span></div>
              <div className="icon">
                <i className="bi bi-airplane" style={{ color: '#fd7e14' }}></i>
              </div>
              <ul>
              <li>Yearly Subscription</li>
                <li>Billing and Invoicing</li>
                <li>Single User Login</li>
                <li>Inventory Management</li>
                <li>Customer Management</li>
                <li>Notifications and Alerts</li>
                <li>Prescription Management</li>
                <li>New Feature Updates</li>
              </ul>
              <a href="/plans" target="_blank" rel="noopener noreferrer" className="btn-buy">View Details</a>
            </div>
          </div>
          {/* End Pricing Item */}

          {/* Ultimate Plan */}
          <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="400">
            <div className="pricing-tem">
              <h3 style={{ color: '#0d6efd' }}>Ultimate Plan</h3>
              <div className="price"><sup>₹</sup>{PLAN_PRICING.ULTIMATE_VALUE}<span> / year</span></div>
              <div className="icon">
                <i className="bi bi-rocket" style={{ color: '#0d6efd' }}></i>
              </div>
              <ul>
                <li>Yearly Subscription</li>
                <li>Billing and Invoicing</li>
                <li>Role Based Access</li>
                <li>Custom Invoice Templates</li>
                <li>Backup and Recovery</li>
                <li>Barcode Scanning</li>
                <li>POS System Integration</li>
                <li>Live Customer Support</li>
              </ul>
              <a href="/plans" target="_blank" rel="noopener noreferrer" className="btn-buy">View Details</a>
            </div>
          </div>
          {/* End Pricing Item */}
        </div>
        {/* End pricing row */}
      </div>
    </section>
  );
};

export default Pricing;