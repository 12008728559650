import React from 'react';

const About = () => {
  return (
    <section id="about" className="about section">
      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-6 content">
            <p className="who-we-are">Who We Are</p>
            <h3>Simplifying Pharmacy Transactions with Precision</h3>
            <p className="fst-italic">
              We specialize in creating intuitive billing solutions that streamline your pharmacy’s operations, 
              making it easier to manage customer transactions.
            </p>
            <ul>
              <li><i className="bi bi-check-circle"></i> <span>Accurate Billing: Ensures quick and precise billing for all pharmacy purchases.</span></li>
              <li><i className="bi bi-check-circle"></i> <span>User-Friendly: Simple interface designed for ease of use by all staff.</span></li>
              <li><i className="bi bi-check-circle"></i> <span>Efficient Operations: Streamlines transaction management, enhancing workflow.</span></li>
            </ul>
            <a href="#" className="read-more"><span>Read More</span><i className="bi bi-arrow-right"></i></a>
          </div>
          <div className="col-lg-6 about-images">
            <div className="row gy-4">
              <div className="col-lg-6">
                <img src="/assets/img/about-company-1.jpg" className="img-fluid" alt="About Company 1" />
              </div>
              <div className="col-lg-6">
                <div className="row gy-4">
                  <div className="col-lg-12">
                    <img src="/assets/img/about-company-2.jpg" className="img-fluid" alt="About Company 2" />
                  </div>
                  <div className="col-lg-12">
                    <img src="/assets/img/about-company-3.jpg" className="img-fluid" alt="About Company 3" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;