// src/components/Home.js
import React from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import About from '../components/About';
import Services from '../components/Services';
import Features from '../components/Features';
import Pricing from '../components/Pricing';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import { FloatingWhatsApp } from '@carlos8a/react-whatsapp-floating-button';
import AgentImage from '../assets/agent.png';
import { WHATSAPP_NUMBER } from '../utils/constants';
const Home = () => {
  return (
    <div>
      <Header />
      <Hero />
      <About />
      <Services />
      <Features />
      <Pricing />
      <Contact />
      <FloatingWhatsApp
        phoneNumber={WHATSAPP_NUMBER} // Required
        accountName='Agent' // Optional
        avatar={AgentImage} // Optional
        initialMessageByServer='Hi there! How can I assist you?' // Optional
        statusMessage='Available' // Optional
        placeholder='Write here...' // Optional
        allowEsc={true} // Optional
      />
      <Footer />
    </div>
  );
};

export default Home;