import React from 'react';

const PlanRow = ({ feature, explorer, basic, pro, ultimate }) => {
  return (
    <tr>
      <td>{feature}</td>
      <td>{explorer}</td>
      <td>{basic}</td>
      <td>{pro}</td>
      <td>{ultimate}</td>
    </tr>
  );
};

export default PlanRow;
