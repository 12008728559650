import React from 'react';

const Hero = () => {
  return (
    <section id="hero" className="hero section light-background">
      <div className="container position-relative">
        <div className="row gy-5">
          <div className="col-lg-6 d-flex flex-column justify-content-center">
            <h2>Pharma QuickBill</h2>
            <p>Managing and tracking customer transactions efficiently. Ensuring accurate billing for all pharmacy purchases.</p>
            <div className="d-flex">
              <a href="#about" className="btn-get-started">Live Demo</a>
              <a href="https://www.youtube.com/watch?v=Y7f98aduVJ8" className="glightbox btn-watch-video d-flex align-items-center">
                <i className="bi bi-play-circle"></i><span>Watch Video</span>
              </a>
            </div>
          </div>
          <div className="col-lg-6">
            <img src="/assets/img/hero-img.png" className="img-fluid" alt="Hero" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;